import { appTheme as theme } from './theme/app';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { getLocaleMessages } from './i18n/getLocaleMessages';
import { ErrorBoundary } from './page/ErrorBoundary';
import { CurrentStoreProvider } from './page/MultiStore/CurrentStoreContext';
import { AppRoutes } from './routes/AppRoutes';
import { getBrowserLocale } from './util/getBrowserLocale';
import { ToastProvider } from './util/toast';

const locale = getBrowserLocale();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            useErrorBoundary: true,
            retry: false,
            refetchOnWindowFocus: false,
        },
        mutations: {
            useErrorBoundary: true,
            retry: false,
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <IntlProvider
                locale={locale}
                defaultLocale='en'
                messages={getLocaleMessages(locale)}
                onError={() => undefined}
            >
                <ToastProvider>
                    <QueryClientProvider client={queryClient}>
                        <CurrentStoreProvider>
                            <ErrorBoundary>
                                <Router
                                    basename={
                                        process.env.REACT_APP_URL_BASENAME
                                    }
                                >
                                    <AppRoutes />
                                </Router>
                            </ErrorBoundary>
                        </CurrentStoreProvider>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </ToastProvider>
            </IntlProvider>
        </ThemeProvider>
    );
}

export default App;
