import { Field, MultiSelectInput } from 'akeneo-design-system';
import { useIntl } from 'react-intl';
import React, { FC } from 'react';
import { PublishingChannel } from '../model/PublishingChannel';
import { useDispatch } from '../../store/StoreProvider';

type Props = {
    selectedPublishingChannel: PublishingChannel[];
    publishingChannels: PublishingChannel[];
};

export const B2BCatalogsMultiSelect: FC<Props> = ({
    selectedPublishingChannel,
    publishingChannels,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleChange = (selection: string[]) => {
        dispatch({
            type: 'connectorConfigForm/ProductPublishing/B2BCatalogs/changed',
            b2bCatalogs: publishingChannels.filter((publishingChannel) =>
                selection.includes(publishingChannel.publication_id)
            ),
        });
    };

    return (
        <Field
            fullWidth={true}
            label={intl.formatMessage({
                defaultMessage: 'B2B Catalogs',
                id: 'waQX86',
            })}
        >
            <MultiSelectInput
                value={selectedPublishingChannel.map(
                    (publishingChannel) => publishingChannel.publication_id
                )}
                emptyResultLabel={intl.formatMessage({
                    defaultMessage: 'No B2B catalogs found',
                    id: 'NaqjT8',
                })}
                placeholder={intl.formatMessage({
                    id: 'S93g6k',
                    defaultMessage: 'Select B2B Catalogs',
                })}
                onChange={handleChange}
                data-testid='value'
                openLabel=''
                removeLabel=''
                readOnly={false}
            >
                {publishingChannels.map((publishingChannel) => (
                    <MultiSelectInput.Option
                        key={publishingChannel.publication_id}
                        title={publishingChannel.catalog_title}
                        value={publishingChannel.publication_id}
                    >
                        {publishingChannel.catalog_title}
                    </MultiSelectInput.Option>
                ))}
            </MultiSelectInput>
        </Field>
    );
};
