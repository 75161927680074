import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { GlobalReportModel } from '../../model/SynchronizationReportModel';

const Green = styled.span`
    color: ${({ theme }) => theme.color.green140};
`;
const Orange = styled.span`
    color: ${({ theme }) => theme.color.yellow140};
`;
const Red = styled.span`
    color: ${({ theme }) => theme.color.red140};
`;

const Grey = styled.span`
    color: ${({ theme }) => theme.color.grey140};
`;

type Props = {
    globalReport: GlobalReportModel | null;
};

export const GlobalReportProcessedProduct = ({ globalReport }: Props) => {
    return (
        <>
            {typeof globalReport?.product_processed !== 'undefined' && (
                <span>
                    {/* Product processed: */}
                    <FormattedMessage
                        id='zQ3FIS'
                        defaultMessage='Product processed: '
                    />
                    {globalReport?.product_processed > 0 ? (
                        <Green>{globalReport!.product_processed}</Green>
                    ) : (
                        <Grey>{globalReport!.product_processed}</Grey>
                    )}
                </span>
            )}

            {typeof globalReport?.product_model_processed !== 'undefined' && (
                <span>
                    {/* Product models processed: */}
                    <FormattedMessage
                        id='Caczmy'
                        defaultMessage='Product models processed: '
                    />
                    {globalReport?.product_model_processed > 0 ? (
                        <Green>{globalReport!.product_model_processed}</Green>
                    ) : (
                        <Grey>{globalReport!.product_model_processed}</Grey>
                    )}
                </span>
            )}
            {typeof globalReport?.product_variant_processed !== 'undefined' && (
                <span>
                    {/* Variants processed : */}
                    <FormattedMessage
                        id='ZocPS0'
                        defaultMessage='Variants processed: '
                    />
                    {globalReport?.product_variant_processed > 0 ? (
                        <Green>{globalReport!.product_variant_processed}</Green>
                    ) : (
                        <Grey>{globalReport!.product_variant_processed}</Grey>
                    )}
                </span>
            )}
            {typeof globalReport?.synchronization_warning !== 'undefined' && (
                <span>
                    {/* Warnings: */}
                    <FormattedMessage id='nokKGM' defaultMessage='Warnings: ' />
                    {globalReport?.synchronization_warning > 0 ? (
                        <Orange>{globalReport!.synchronization_warning}</Orange>
                    ) : (
                        <Grey>{globalReport!.synchronization_warning}</Grey>
                    )}
                </span>
            )}
            {typeof globalReport?.synchronization_error !== 'undefined' && (
                <span>
                    {/* Errors: */}
                    <FormattedMessage id='Rjoa9F' defaultMessage='Errors: ' />
                    {globalReport?.synchronization_error > 0 ? (
                        <Red>{globalReport!.synchronization_error}</Red>
                    ) : (
                        <Grey>{globalReport!.synchronization_error}</Grey>
                    )}
                </span>
            )}
        </>
    );
};
