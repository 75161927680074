import { Button, Modal, ProductsIllustration } from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useState } from 'react';
import { SynchronizationScope } from '../../model/SynchronizationScope';
import { SynchronizationScopeLabel } from '../../component/SynchronizationScopeLabel';
import { FiltersForm } from './FiltersForm';
import { FilterPimAttribute } from '../../model/FilterPimAttribute';

type Props = {
    closeModal: () => void;
    onLaunch: (
        minCompleteness: number | null,
        filterAttribute: string | null
    ) => void;
    scope: SynchronizationScope;
    filterPimAttributes: FilterPimAttribute[];
    isPreExistingCatalogMappingConfigured: boolean;
    isProductMediaMappingEmpty: boolean;
    isMetafieldAttributeMappingEmpty: boolean;
    isMetafieldAssociationMappingEmpty: boolean;
    isSecondaryLocalesMappingEmpty: boolean;
    isMarketsPricingMappingEmpty: boolean;
    isPublishingChannelsConfigured: boolean;
};

const SynchronizationModal = ({
    closeModal,
    onLaunch,
    scope,
    filterPimAttributes,
    isPreExistingCatalogMappingConfigured,
    isProductMediaMappingEmpty,
    isMetafieldAttributeMappingEmpty,
    isMetafieldAssociationMappingEmpty,
    isSecondaryLocalesMappingEmpty,
    isMarketsPricingMappingEmpty,
    isPublishingChannelsConfigured,
}: Props) => {
    const [minCompleteness, setMinCompleteness] = useState<number | null>(null);
    const [filterAttribute, setFilterAttribute] = useState<string | null>(null);

    const handleLaunchSynchronization = () => {
        onLaunch(minCompleteness, filterAttribute);
        closeModal();
    };
    const intl = useIntl();
    return (
        <Modal
            onClose={closeModal}
            closeTitle={intl.formatMessage({
                id: '47FYwb',
                defaultMessage: 'Cancel',
            })}
            illustration={<ProductsIllustration />}
        >
            <Modal.SectionTitle color='brand'>
                <FormattedMessage
                    id='zJoa5X'
                    defaultMessage='Manual synchronization'
                />
            </Modal.SectionTitle>
            <Modal.Title>
                <SynchronizationScopeLabel scope={scope} />
            </Modal.Title>

            <FiltersForm
                scope={scope}
                minCompleteness={minCompleteness}
                setMinCompleteness={setMinCompleteness}
                filterAttribute={filterAttribute}
                setFilterAttribute={setFilterAttribute}
                filterPimAttributes={filterPimAttributes}
                isPreExistingCatalogMappingConfigured={
                    isPreExistingCatalogMappingConfigured
                }
                isProductMediaMappingEmpty={isProductMediaMappingEmpty}
                isMetafieldAttributeMappingEmpty={
                    isMetafieldAttributeMappingEmpty
                }
                isMetafieldAssociationMappingEmpty={
                    isMetafieldAssociationMappingEmpty
                }
                isSecondaryLocalesMappingEmpty={isSecondaryLocalesMappingEmpty}
                isMarketsPricingMappingEmpty={isMarketsPricingMappingEmpty}
                isPublishingChannelsConfigured={isPublishingChannelsConfigured}
            />

            <Modal.BottomButtons>
                <Button level='tertiary' onClick={closeModal}>
                    <FormattedMessage id='47FYwb' defaultMessage='Cancel' />
                </Button>
                <Button level='primary' onClick={handleLaunchSynchronization}>
                    <FormattedMessage
                        id='yAOnoV'
                        defaultMessage='Launch synchronization'
                    />
                </Button>
            </Modal.BottomButtons>
        </Modal>
    );
};

export { SynchronizationModal };
